export default (ctx, inject) => {
  const openModal = (component, props, idempotencyKey = null) => {
    return ctx.store.dispatch('modals/open', {
      component,
      props,
      idempotencyKey
    })
  }

  inject('modal', openModal)
}
