
import FlashSaleUtils from '@/components/next/layout/flashsale/FlashSaleUtils'

export default {
  name: 'FlashSaleSidebarBanner',
  mixins: [FlashSaleUtils],
  methods: {
    chooseSubscription () {
      this.$errors.chooseSubscription()
    }
  }
}
