
import CfButton from '~/components/next/ui/button/CfButton.vue'

export default {
  name: 'TrialExtensionOffer',
  components: { CfButton },
  props: {
    loading: Boolean
  },
  methods: {
    acceptOffer () {
      this.$emit('accept')
    }
  }
}
