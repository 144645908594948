import * as configcat from 'configcat-js'

export default ({ $auth, $modal }, inject) => {
  const client = configcat.getClient(
    process.env.CONFIGCAT_SDK_KEY,
    configcat.PollingMode.AutoPoll,
    {
      dataGovernance: configcat.DataGovernance.EuOnly
    })

  inject('flags', async (key, defaultValue) => {
    const user = new configcat.User(
      $auth.user?.workspace?.id,
      $auth.user?.email,
      null,
      {
        slug: $auth.user?.workspace?.slug
      })

    return await client.getValueAsync(key, defaultValue, user)
  })
}
