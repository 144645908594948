
import CfProBadge from '~/components/next/ui/elements/CfProBadge.vue'

export default {
  name: 'NavigationItem',
  components: { CfProBadge },
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'circle-question'
    },
    route: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: null
    },
    textBadge: {
      type: String,
      default: null
    },
    proBadge: Boolean
  },
  computed: {
    buttonClass () {
      if (this.active === true) {
        return 'bg-indigo-900'
      }

      return this.active ? this.activeClass : null
    },
    activeClass () {
      if (this.activeOverwritten) {
        return null
      }

      return 'bg-indigo-900'
    },
    activeOverwritten () {
      return this.active !== null
    }
  },
  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
}
