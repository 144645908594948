export default $axios => ({
  show () {
    return $axios.get('api_token')
  },

  store () {
    return $axios.post('api_token')
  },

  destroy () {
    return $axios.delete('api_token')
  }
})
