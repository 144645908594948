
import DeterminesBillingSituation from '@/components/next/workspace/billing/subscription/DeterminesBillingSituation'

export default {
  name: 'SubscriptionChooserMasthead',
  mixins: [DeterminesBillingSituation],
  props: {
    featureUnavailable: Boolean
  },
  computed: {
    text () {
      return this.$t('workspace.billing.subscription.masthead.' + this.key)
    },

    key () {
      if (this.$auth.user.workspace.demo.ephemeral) {
        return 'ephemeral'
      }

      if (this.onBenefitsPage) {
        return 'paid_benefits'
      }

      if (this.enrolmentActive) {
        return 'upgrade_possible'
      }

      if (this.productFlavor === 'members_only') {
        return 'upgrade_possible'
      }

      if (this.trialOngoing) {
        return 'trial_ongoing'
      }

      if (this.trialEnded) {
        return 'trial_ended'
      }

      if (this.subscriptionValid && this.featureUnavailable) {
        return 'upgrade_required'
      }

      if (this.productFlavor === 'express') {
        return 'upgrade_possible'
      }

      return 'generic'
    },

    onFinancePage () {
      return this.$route.path.startsWith('/finance')
    },

    onBenefitsPage () {
      return this.$route.path.startsWith('/benefits')
    }
  }
}
