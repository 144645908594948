export default $axios => ({
  index (listId) {
    return $axios.get(`lists/${listId}/list_imports`)
  },

  store (listId, data) {
    return $axios.post(`lists/${listId}/list_imports`, data)
  },

  show (importId) {
    return $axios.get(`list_imports/${importId}`)
  },

  update (importId, data) {
    return $axios.put(`list_imports/${importId}`, data)
  },

  destroy (importId) {
    return $axios.delete(`list_imports/${importId}`)
  }
})
