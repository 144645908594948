
export default {
  name: 'CfLabel',
  props: {
    error: Boolean,
    gray: Boolean
  },
  computed: {
    classes () {
      return {
        'text-red-600': this.error,
        'text-gray-700': !this.gray && !this.error,
        'text-gray-500': this.gray && !this.error
      }
    }
  }
}
