export default $axios => ({
  index () {
    return $axios.get('number_blocks')
  },

  show (numberBlockType) {
    return $axios.get(`number_blocks/${numberBlockType}`)
  },

  update (numberBlockType, data) {
    return $axios.put(`number_blocks/${numberBlockType}`, data)
  }
})
