export default $axios => ({
  index (params) {
    return $axios.get('lists', { params })
  },

  store (data) {
    return $axios.post('lists', data)
  },

  show (listId) {
    return $axios.get(`lists/${listId}`)
  },

  update (listId, data) {
    return $axios.put(`lists/${listId}`, data)
  },

  destroy (listId) {
    return $axios.delete(`lists/${listId}`)
  }
})
