export default $axios => ({
  index (params) {
    return $axios.get('expenses', { params })
  },

  store (data) {
    return $axios.post('expenses', data)
  },

  show (expenseId) {
    return $axios.get(`expenses/${expenseId}`)
  },

  update (expenseId, data) {
    return $axios.put(`expenses/${expenseId}`, data)
  }
})
