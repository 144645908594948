export default $axios => ({
  show () {
    return $axios.get('member_provider')
  },

  store (data) {
    return $axios.post('member_provider', data)
  },

  update (data) {
    return $axios.put('member_provider', data)
  },

  destroy () {
    return $axios.delete('member_provider')
  }
})
