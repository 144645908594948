export default $axios => ({
  index () {
    return $axios.get('accounts')
  },

  store (data) {
    return $axios.post('accounts', data)
  },

  show (accountId) {
    return $axios.get(`accounts/${accountId}`)
  },

  update (accountId, data) {
    return $axios.put(`accounts/${accountId}`, data)
  },

  destroy (accountId) {
    return $axios.delete(`accounts/${accountId}`)
  }
})
