export default (ctx, inject) => {
  const auth = ctx.$auth

  const access = {
    hasPermission (permission) {
      if (!auth.user) {
        return false
      }

      return auth.user?.permissions?.includes(permission) || false
    },

    doesntHavePermission (permission) {
      return !this.hasPermission(permission)
    },

    hasFeature (feature) {
      if (!auth.user) {
        return false
      }

      return auth.user?.workspace?.product?.features?.includes(feature) || false
    },

    doesntHaveFeature (feature) {
      return !this.hasFeature(feature)
    },

    isCertificatesStandalone () {
      if (!auth.user) {
        return false
      }

      return auth.user?.workspace?.product?.flavor === 'certificates' || false
    },

    managesDivision () {
      return Boolean(auth.user?.workspace?.managed_division)
    },

    allowsSubdivisions () {
      return auth.user?.workspace?.managed_division?.layer?.allows_subdivisions || false
    }
  }

  inject('access', access)
}
