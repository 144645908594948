export default $axios => ({
  index (params) {
    return $axios.get('certificates', { params })
  },

  store (data) {
    return $axios.post('certificates', data)
  },

  show (certificateId) {
    return $axios.get(`certificates/${certificateId}`)
  },

  update (certificateId, data) {
    return $axios.put(`certificates/${certificateId}`, data)
  },

  destroy (certificateId) {
    return $axios.delete(`certificates/${certificateId}`)
  }
})
