
import NavigationItems from '@/components/next/layout/navigation/items/NavigationItems'
import NavigationItem from '@/components/next/layout/navigation/items/NavigationItem'
import HandlesDismissals from '@/components/next/utilities/HandlesDismissals'

export default {
  name: 'NavigationCertificatesStandaloneTopItems',
  components: { NavigationItems, NavigationItem },
  mixins: [HandlesDismissals],
  computed: {
    contactListUrl () {
      return `/lists/${this.$auth.user?.workspace?.contact_list_id}`
    }
  }
}
