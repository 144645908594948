
export default {
  name: 'CfDot',
  props: {
    color: {
      type: String,
      default: 'green'
    }
  },
  computed: {
    outerClasses () {
      return {
        'flex-none h-4 w-4 rounded-full flex items-center justify-center': true,
        'bg-green-100': this.color === 'green',
        'bg-amber-100': this.color === 'yellow',
        'bg-red-50': this.color === 'red',
        'bg-gray-100': this.color === 'gray'
      }
    },
    innerClasses () {
      return {
        'h-2 w-2 rounded-full': true,
        'bg-green-600': this.color === 'green',
        'bg-amber-400': this.color === 'yellow',
        'bg-red-400': this.color === 'red',
        'bg-gray-300': this.color === 'gray'
      }
    }
  }
}
