import { v4 as uuid } from 'uuid'

export const state = () => []

let modalComponents = {}
let modalProps = {}

export const mutations = {
  open (state, { component, props, idempotencyKey, resolve }) {
    const modalId = uuid()
    modalComponents[modalId] = component
    modalProps[modalId] = props

    state.push({
      id: modalId,
      idempotencyKey,
      resolve
    })
  },

  close (state, { modalId, payload }) {
    const index = state.findIndex(modal => modal.id === modalId)

    if (index === -1) {
      return
    }

    if (state[index].resolve) {
      state[index].resolve(payload)
    }

    state.splice(index, 1)
    delete modalComponents[modalId]
    delete modalProps[modalId]
  },

  forceCloseAll (state) {
    state.splice(0, state.length)
    modalComponents = {}
    modalProps = {}
  }
}

export const actions = {
  open ({ commit, getters }, { component, props, idempotencyKey = null }) {
    if (getters.alreadyOpened(idempotencyKey)) {
      return
    }

    return new Promise((resolve) => {
      commit('open', { component, props, idempotencyKey, resolve })
    })
  }
}

export const getters = {
  openedModals (state) {
    return state.map(modal => ({
      id: modal.id,
      component: modalComponents[modal.id],
      props: modalProps[modal.id]
    }))
  },

  alreadyOpened: state => (idempotencyKey) => {
    if (!idempotencyKey) {
      return false
    }

    return state.some(modal => modal.idempotencyKey === idempotencyKey)
  }
}
