export default {
  computed: {
    workspace () {
      return this.$auth.user?.workspace || null
    },

    productFlavor () {
      return this.workspace?.product?.flavor || false
    },

    productActive () {
      return this.workspace?.product?.active || false
    },

    productInactive () {
      return !this.productActive
    },

    latestSubscription () {
      return this.workspace?.subscription || null
    },

    neverWasSubscribed () {
      return this.latestSubscription === null
    },

    trialOngoing () {
      return this.workspace?.trial.ongoing || false
    },

    trialEnded () {
      return this.neverWasSubscribed && !this.trialOngoing
    },

    trialDays () {
      return this.workspace?.trial.days_left
    },

    subscriptionValid () {
      return this.latestSubscription?.valid || false
    },

    subscriptionRecurring () {
      return this.latestSubscription !== null &&
        !this.latestSubscription.cancelled
    },

    subscriptionCancelled () {
      return this.latestSubscription?.cancelled || false
    },

    subscriptionEnded () {
      return this.latestSubscription?.ended || false
    },

    subscriptionCycle () {
      return this.latestSubscription?.cycle || null
    },

    latestEnrolment () {
      return this.workspace?.enrolment || null
    },

    enrolmentPending () {
      return this.latestEnrolment !== null && this.latestEnrolment.pending
    },

    enrolmentActive () {
      return this.latestEnrolment !== null && this.latestEnrolment.active
    },

    enrolmentCancelled () {
      return this.latestEnrolment !== null && this.latestEnrolment.cancelled
    },

    upgradeScheduledAfterEnrolmentEnd () {
      return this.latestEnrolment !== null && this.latestEnrolment.upgrade_scheduled
    },

    canChangeSubscription () {
      if (this.enrolmentActive) {
        return this.latestEnrolment.pool.stripe_prices.length > 0
      }

      return true
    },

    canCancelSubscription () {
      return this.subscriptionRecurring || this.upgradeScheduledAfterEnrolmentEnd
    },

    hasPaymentMethod () {
      return Boolean(this.workspace?.payment_method)
    }
  }
}
