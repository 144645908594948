export default $axios => ({
  index (listId) {
    return $axios.get(`lists/${listId}/labels`)
  },

  store (listId, data) {
    return $axios.post(`lists/${listId}/labels`, data)
  },

  update (labelId, data) {
    return $axios.put(`labels/${labelId}`, data)
  },

  destroy (labelId) {
    return $axios.delete(`labels/${labelId}`)
  }
})
