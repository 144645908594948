
export default {
  name: 'EphemeralBanner',
  methods: {
    register () {
      const registrationUrl = this.$access.isCertificatesStandalone()
        ? '/auth/register?certificates_standalone=1'
        : '/auth/register'

      this.$router.push(registrationUrl)
    }
  }
}
