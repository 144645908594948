import { render, staticRenderFns } from "./PoolBranding.vue?vue&type=template&id=0f6fb160&scoped=true"
import script from "./PoolBranding.vue?vue&type=script&lang=js"
export * from "./PoolBranding.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f6fb160",
  null
  
)

export default component.exports