
import OnboardingStep from '@/components/next/layout/finance_onboarding/OnboardingStep'
import IconButton from '@/components/common/buttons/IconButton'

export default {
  name: 'FinanceOnboardingBanner',
  components: { OnboardingStep, IconButton },

  data () {
    return {
      expandedStep: null,
      dismissed: null,

      report: null,
      videoPlayed: false,

      grabbingAttention: false
    }
  },

  computed: {
    bannerVisible () {
      return this.bannerAvailable && !this.reportLoading
    },

    currentPath () {
      return this.$route.path
    },

    bannerAvailable () {
      if (this.allComplete) {
        return false
      }

      const paths = [
        '/finance/accounts',
        '/finance/receipts'
      ]

      return paths.includes(this.currentPath)
    },

    steps () {
      if (this.ephemeralWorkspace) {
        return ['demo_video', 'demo_expense', 'demo_assignment', 'demo_generated_expense', 'demo_everything_assigned']
      } else {
        return ['video', 'account', 'expense', 'teamtool', 'assignment']
      }
    },

    completeSteps () {
      if (this.reportLoading) {
        return []
      }

      const completeSteps = []

      if (this.report.bank_connected) {
        completeSteps.push('account')
      }

      if (this.report.expense_created) {
        completeSteps.push('expense', 'demo_expense')
      }

      if (this.report.expense_generated) {
        completeSteps.push('demo_generated_expense')
      }

      if (this.report.receipt_scanned) {
        completeSteps.push('teamtool')
      }

      if (this.report.expense_assigned) {
        completeSteps.push('assignment', 'demo_assignment')
      }

      if (this.report.everything_assigned) {
        completeSteps.push('demo_everything_assigned')
      }

      if (completeSteps.length > 0 || this.videoPlayed) {
        completeSteps.push('video', 'demo_video')
      }

      return completeSteps
    },

    completeStepsSet () {
      return new Set(this.completeSteps)
    },

    allComplete () {
      return this.incompleteStepCount === 0
    },

    incompleteStepCount () {
      return this.steps.filter(step => !this.isComplete(step)).length
    },

    reportLoading () {
      return !this.report
    },

    ephemeralWorkspace () {
      return this.$auth.user?.workspace?.demo?.ephemeral
    },

    salesMode () {
      return this.$auth.user?.workspace?.demo?.sales_mode
    },

    bannerDismissed: {
      get () {
        if (this.dismissed !== null) {
          return this.dismissed
        }

        if (this.salesMode) {
          return true
        }

        return this.$auth.$storage.getUniversal('finance_onboarding_dismissed') || false
      },
      set (dismissed) {
        this.dismissed = dismissed
        this.$auth.$storage.setUniversal('finance_onboarding_dismissed', dismissed)
      }
    }
  },

  watch: {
    bannerAvailable: {
      immediate: true,
      handler () {
        this.fetchReport()
      }
    },

    incompleteStepCount (newIncomplete, oldIncomplete) {
      if (this.bannerVisible && newIncomplete < oldIncomplete) {
        this.grabAttention()
        this.expandFirstIncomplete()
      }
    },

    currentPath () {
      if (this.bannerDismissed) {
        this.grabAttention()
      }
    }
  },

  mounted () {
    this.$nuxt.$on('refresh-onboarding-steps', this.fetchReport)
  },

  beforeDestroy () {
    this.$nuxt.$off('refresh-onboarding-steps', this.fetchReport)
  },

  methods: {
    isExpanded (step) {
      return this.expandedStep === step
    },

    expandStep (step) {
      this.expandedStep = step
    },

    expandFirstIncomplete () {
      const firstIncomplete = this.steps.find(step => !this.isComplete(step))
      this.expandStep(firstIncomplete)
    },

    isComplete (step) {
      return this.completeStepsSet.has(step)
    },

    async fetchReport () {
      if (!this.bannerAvailable) {
        return
      }

      const response = await this.$api.financeOnboarding.index()
      this.report = response.data.data
      this.expandFirstIncomplete()
    },

    toggleDismissed () {
      this.bannerDismissed = !this.bannerDismissed
    },

    grabAttention () {
      this.grabbingAttention = true
      setTimeout(() => { this.grabbingAttention = false }, 300)
    }
  }
}
