
import NavigationItem from '@/components/next/layout/navigation/items/NavigationItem'
import AnnoucementsModal from '@/components/next/layout/announcements/AnnoucementsModal.vue'
import HandlesDismissals from '@/components/next/utilities/HandlesDismissals'

export default {
  name: 'AnnoucementNavigationItem',
  components: {
    NavigationItem
  },
  mixins: [HandlesDismissals],
  data () {
    return {
      announcements: []
    }
  },
  computed: {
    dismissedAnnouncements () {
      return this.announcements.filter(announcement => this.isDismissed(announcement.dismissal_key))
    },

    undismissedAnnouncements () {
      return this.announcements.filter(announcement => !this.isDismissed(announcement.dismissal_key))
    },

    badgeText () {
      return this.undismissedAnnouncements.length > 0
        ? String(this.undismissedAnnouncements.length)
        : null
    },

    anyAnnouncementsVisible () {
      return this.announcements.length > 0
    }
  },

  async mounted () {
    await this.fetchAnnouncements()
    this.showUndismissedPopupAnnouncements()
  },

  methods: {
    async fetchAnnouncements () {
      const response = await this.$api.announcements.index()
      this.announcements = response.data.data
    },

    showAllAnnouncements () {
      this.showAnnouncements([
        ...this.undismissedAnnouncements,
        ...this.dismissedAnnouncements
      ])
    },

    showUndismissedPopupAnnouncements () {
      const announcements = this.undismissedAnnouncements.filter(announcement => announcement.popup)
      this.showAnnouncements(announcements)
    },

    showAnnouncements (announcements) {
      if (announcements.length === 0) {
        return
      }

      this.$modal(AnnoucementsModal, {
        announcements
      })
    }
  }
}
