import { DateTime } from 'luxon'
import DeterminesBillingSituation
  from '@/components/next/workspace/billing/subscription/DeterminesBillingSituation'

const countdownDate = DateTime.fromISO('2023-06-25T23:59:59')
// Please also change date in FlashSaleSubscriptionBanner.vue

const promotionCode = 'SOFORT40'
const percentOff = 40

export default {
  data () {
    return {
      flashSaleOngoing: false,
      currentDate: DateTime.now()
    }
  },
  mixins: [DeterminesBillingSituation],

  async beforeMount () {
    this.flashSaleOngoing = await this.$flags('flashsale', false)
  },

  mounted () {
    setInterval(() => {
      this.recalculateCountdown()
    }, 1000)
  },

  computed: {
    flashSaleVisible () {
      if (this.flashSaleEnded) {
        return false
      }

      return this.flashSaleOngoing &&
        this.neverWasSubscribed &&
        !this.$auth.user.workspace.product.paying
    },

    flashSaleEnded () {
      return countdownDate < this.currentDate
    },

    flashSaleCountdown () {
      const diff = countdownDate.diff(this.currentDate, ['hours', 'minutes', 'seconds'])

      const hours = Math.round(diff.hours).toString().padStart(2, '0')
      const minutes = Math.round(diff.minutes).toString().padStart(2, '0')
      const seconds = Math.round(diff.seconds).toString().padStart(2, '0')

      return `${hours}:${minutes}:${seconds} h`
    },

    flashSalePromotionCode () {
      return promotionCode
    },

    flashSalePercentOff () {
      return percentOff
    }
  },

  methods: {
    recalculateCountdown () {
      this.currentDate = DateTime.now()
    }
  }
}
