export default $axios => ({
  store (data) {
    return $axios.post('nordigen/requisitions', data)
  },

  show (reference) {
    return $axios.get(`nordigen/requisitions/${reference}`)
  },

  update (reference, data) {
    return $axios.put(`nordigen/requisitions/${reference}`, data)
  }
})
