
export default {
  name: 'EnrolmentBranding',
  computed: {
    workspace () {
      return this.$auth.user?.workspace
    },

    hasActiveEnrolment () {
      return this.workspace && this.workspace.enrolment && this.workspace.enrolment.active
    },

    partnerName () {
      return this.hasActiveEnrolment ? this.workspace.enrolment.pool.name : null
    },

    partnerUrl () {
      return this.hasActiveEnrolment ? this.workspace.enrolment.pool.url : null
    },

    partnerLogoUrl () {
      return this.hasActiveEnrolment ? this.workspace.enrolment.pool.logo : null
    },

    hasLogo () {
      return this.partnerLogoUrl !== null
    }
  }
}
