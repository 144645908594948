export default $axios => ({
  store (data) {
    return $axios.post('recoveries', data)
  },

  show (recoveryToken) {
    return $axios.get(`recoveries/${recoveryToken}`)
  },

  update (recoveryToken, data) {
    return $axios.put(`recoveries/${recoveryToken}`, data)
  }
})
