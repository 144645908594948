export default $axios => ({
  show (teamtoolSessionType) {
    return $axios.get(`teamtool_sessions/${teamtoolSessionType}`)
  },

  store (teamtoolSessionType) {
    return $axios.post(`teamtool_sessions/${teamtoolSessionType}`)
  },

  destroy (teamtoolSessionType) {
    return $axios.delete(`teamtool_sessions/${teamtoolSessionType}`)
  }
})
