
export default {
  name: 'AuthFooter',
  props: {
    onDark: {
      type: Boolean,
      default: false
    }
  }
}
