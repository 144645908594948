
export default {
  name: 'CogwheelMenuItem',
  props: {
    route: {
      type: String,
      default: ''
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
