import { Intercom, boot as bootIntercom } from '@intercom/messenger-js-sdk'

export default (ctx) => {
  function getPreparedUserInfo () {
    if (!ctx.$auth.user) {
      return {}
    }

    if (ctx.$auth.user.workspace.demo.ephemeral) {
      return {}
    }

    return {
      user_id: ctx.$auth.user.id,
      name: ctx.$auth.user.name,
      email: ctx.$auth.user.email,
      user_hash: ctx.$auth.user.intercom_identity_verification_hash
    }
  }

  function getHorizontalPadding () {
    if (ctx.route.path.startsWith('/forms/')) {
      return 400
    }

    return 20
  }

  function getIntercomConfig () {
    return {
      app_id: 'v2de58cy',
      region: 'eu',
      horizontal_padding: getHorizontalPadding(),
      ...getPreparedUserInfo()
    }
  }

  Intercom(getIntercomConfig())

  ctx.$auth.$storage.watchState('user', () => {
    bootIntercom(getIntercomConfig())
  })

  ctx.app.router.afterEach((to, from) => {
    if (to.path.startsWith('/forms/') || from.path.startsWith('/forms/')) {
      bootIntercom(getIntercomConfig())
    }
  })
}
