export default $axios => ({
  index (params) {
    return $axios.get('costunits', { params })
  },

  update (data) {
    return $axios.put('costunits', { data })
  },

  store (data) {
    return $axios.post('costunits', data)
  },

  show (costunitId) {
    return $axios.get(`costunits/${costunitId}`)
  },

  destroy (costunitId) {
    return $axios.delete(`costunits/${costunitId}`)
  }
})
