export default $axios => ({
  index (repoSpecificId) {
    return $axios.get(`triggers/${repoSpecificId}`)
  },

  show (repoSpecificId, triggerType) {
    return $axios.get(`triggers/${repoSpecificId}/${triggerType}`)
  },

  update (repoSpecificId, triggerType, data) {
    return $axios.put(`triggers/${repoSpecificId}/${triggerType}`, data)
  },

  destroy (repoSpecificId, triggerType) {
    return $axios.delete(`triggers/${repoSpecificId}/${triggerType}`)
  }
})
