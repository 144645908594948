export default $axios => ({
  index (certificateId, params, config) {
    return $axios.get(`certificates/${certificateId}/inspections`, {
      params,
      ...config
    })
  },

  show (inspectionId) {
    return $axios.get(`inspections/${inspectionId}`)
  },

  update (inspectionId, data) {
    return $axios.put(`inspections/${inspectionId}`, data)
  },

  destroy (inspectionId) {
    return $axios.delete(`inspections/${inspectionId}`)
  }
})
