
export default {
  name: 'CfModal',
  props: {
    size: {
      type: String,
      default: 'sm'
    },
    padding: {
      type: Boolean,
      default: true
    },
    fullHeight: Boolean,
    clip: Boolean
  },
  data () {
    return {
      closingEnabled: false
    }
  },
  computed: {
    containerClasses () {
      return {
        'min-w-0': true,
        'max-w-md': this.size === 'xs',
        'max-w-lg': this.size === 'sm',
        'max-w-2xl': this.size === 'md',
        'max-w-3xl': this.size === 'lg',
        'max-w-4xl': this.size === 'xl',
        'max-w-5xl': this.size === '2xl',
        'max-w-8xl h-full': this.size === '3xl',
        'p-8': this.padding,
        'overflow-hidden': this.clip,
        'h-full': this.fullHeight
      }
    }
  },
  methods: {
    handleMousedown () {
      this.closingEnabled = true
    },

    handleMouseup () {
      if (this.closingEnabled) {
        this.$emit('close')
        this.closingEnabled = false
      }
    },

    handleMouseleave () {
      this.closingEnabled = false
    }
  }
}
