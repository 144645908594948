
import CfModal from '~/components/next/ui/modal/CfModal.vue'
import SubscriptionChooserMasthead from '@/components/next/workspace/billing/subscription/masthead/SubscriptionChooserMasthead'
import SubscriptionPriceChooser from '@/components/next/workspace/billing/subscription/prices/SubscriptionPriceChooser'
import SubscriptionActions from '@/components/next/workspace/billing/subscription/actions/SubscriptionActions'
import SubscriptionPermissionMissing from '@/components/next/workspace/billing/subscription/errors/SubscriptionPermissionMissing.vue'
import SubscriptionEphemeralWorkspace from '@/components/next/workspace/billing/subscription/errors/SubscriptionEphemeralWorkspace.vue'
import SubscriptionNoPrices from '@/components/next/workspace/billing/subscription/errors/SubscriptionNoPrices.vue'
import FlashSaleSubscriptionBanner from '@/components/next/layout/flashsale/FlashSaleSubscriptionBanner.vue'
import ProrationBanner from '@/components/next/workspace/billing/subscription/banner/ProrationBanner.vue'

import ExtendsTrial from '@/components/next/workspace/billing/subscription/trial/ExtendsTrial'
import BillingModalUtils from '@/components/next/workspace/billing/subscription/BillingModalUtils'
import FlashSaleUtils from '@/components/next/layout/flashsale/FlashSaleUtils'

export default {
  name: 'SubscriptionModal',

  components: {
    CfModal,
    SubscriptionChooserMasthead,
    SubscriptionPriceChooser,
    SubscriptionActions,
    SubscriptionPermissionMissing,
    SubscriptionEphemeralWorkspace,
    SubscriptionNoPrices,
    FlashSaleSubscriptionBanner,
    ProrationBanner
  },

  mixins: [
    ExtendsTrial,
    BillingModalUtils,
    FlashSaleUtils
  ],

  props: {
    featureUnavailable: Boolean
  },

  data () {
    return {
      prices: [],
      selectedPriceId: null
    }
  },

  computed: {
    enrolment () {
      return this.$auth.user.workspace.enrolment
    },
    visiblePool () {
      return this.enrolment?.accepted ? this.enrolment.pool : null
    },
    noPermission () {
      return this.$access.doesntHavePermission('admin')
    },
    isEphemeral () {
      return this.$auth.user.workspace.demo.ephemeral
    },
    noPrices () {
      return this.prices.length === 0 && !this.loading
    },
    showProrationBanner () {
      if (!this.subscriptionValid) {
        return false
      }

      return this.subscriptionCancelled || this.flavor !== 'pro' || this.subscriptionCycle === 'monthly'
    },
    modalSize () {
      if (this.isEphemeral) {
        return 'sm'
      }

      if (this.loading) {
        return '2xl'
      }

      if (this.prices.length >= 3) {
        return '2xl'
      } else if (this.prices.length === 2) {
        return 'md'
      } else {
        return 'xs'
      }
    }
  },

  async mounted () {
    await this.fetchPrices()
  },

  methods: {
    async fetchPrices () {
      try {
        this.markAsLoading()
        const response = await this.$api.stripePrices.index()
        this.prices = response.data.data
      } catch (err) {
        this.$errors.handle(err)
      } finally {
        this.markAsDone()
      }
    },

    async submit () {
      this.markAsSubmitting()

      const response = await this.$api.subscription.update({
        stripe_price_id: this.selectedPriceId
      })

      if (response.status === 200) {
        location.assign(response.data.redirect_url)
      } else {
        await this.refreshAndClose()
      }

      this.markAsDone()
    }
  }
}
