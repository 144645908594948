export default $axios => ({
  index (listId, params) {
    return $axios.get(`lists/${listId}/columns`, { params })
  },

  update (listId, data) {
    return $axios.put(`lists/${listId}/columns`, { data })
  },

  destroy (customColumnId) {
    return $axios.delete(`custom_columns/${customColumnId}`)
  },

  distinctValues (customColumnId) {
    return $axios.get(`custom_columns/${customColumnId}/distinct_values`)
  }
})
