export default $axios => ({
  index () {
    return $axios.get('users')
  },

  store (data) {
    return $axios.post('users', data)
  },

  show (userId) {
    return $axios.get(`users/${userId}`)
  },

  update (userId, data) {
    return $axios.put(`users/${userId}`, data)
  },

  destroy (userId) {
    return $axios.delete(`users/${userId}`)
  }
})
