export default $axios => ({
  index () {
    return $axios.get('currencies')
  },

  show (symbol, date) {
    return $axios.get('currencies/' + symbol, {
      params: { date }
    })
  }
})
