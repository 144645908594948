
export default {
  name: 'IconButton',
  props: {
    icon: {
      type: [String, Array],
      required: true
    },
    size: {
      type: Number,
      default: 4
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click () {
      if (this.disabled) {
        return
      }

      this.$emit('click')
    }
  }
}
