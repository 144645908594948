import TrialExtensionOffer from '@/components/next/workspace/billing/subscription/trial/TrialExtensionOffer'

export default {
  components: { TrialExtensionOffer },
  data () {
    return {
      extendingTrial: false
    }
  },

  computed: {
    trialExtensible () {
      return this.$auth.user.workspace.trial.extensible
    }
  },

  methods: {
    async extendTrial () {
      try {
        this.extendingTrial = true
        await this.$api.trialExtensions.store()
        setTimeout(this.refreshAndClose, 1000)
      } catch (err) {
        this.$errors.handle(err)
        this.extendingTrial = false
      }
    }
  }
}
