
export default {
  name: 'LegalLink',
  props: {
    url: {
      type: String,
      required: true
    }
  }
}
