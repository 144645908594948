export default {
  data () {
    return {
      loading: false,
      submitting: false
    }
  },

  methods: {
    markAsLoading () {
      this.loading = true
    },

    markAsSubmitting () {
      this.submitting = true
    },

    markAsDone () {
      this.loading = false
      this.loading = false
    },

    async refreshAndRedirect (to) {
      await this.refreshSubscription()
      await this.$router.push(to)
    },

    async refreshAndClose () {
      await this.refreshSubscription()
      this.close()
    },

    async refreshSubscription () {
      await this.$auth.fetchUser()
    },

    close () {
      this.$emit('close')
    }
  }
}
