export default $axios => ({
  index (params) {
    return $axios.get('messages', { params })
  },

  store (data) {
    return $axios.post('messages', data)
  },

  show (messageId) {
    return $axios.get(`messages/${messageId}`)
  },

  update (messageId, data) {
    return $axios.put(`messages/${messageId}`, data)
  },

  destroy (messageId) {
    return $axios.delete(`messages/${messageId}`)
  }
})
