export default $axios => ({
  index (params) {
    return $axios.get('receipts', { params })
  },

  store (formData, config) {
    return $axios.post('receipts', formData, config)
  },

  show (receiptId) {
    return $axios.get(`receipts/${receiptId}`, { responseType: 'blob' })
  },

  destroy (receiptId) {
    return $axios.delete(`receipts/${receiptId}`)
  }
})
